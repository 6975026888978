@import url("https://fonts.googleapis.com/css?family=Oswald");
@import url("https://fonts.googleapis.com/css?family=Work+Sans");
@import url("https://fonts.googleapis.com/css?family=Chivo+Mono");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font-oswald {
  font-family: "Oswald";
}

.font-work-sans,
* {
  font-family: "Work Sans";
}

.font-chivo-mono {
  font-family: "Chivo Mono";
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(15 23 42);
  outline: 1px solid slategrey;
}

.bg-appointment {
  background-image: url("./assets/calendar-bg.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
